<template>
	<div class="app-view">
		<div class="controls-container">
		
      <div class="select-wrapper" v-show="0">
        <select v-model="groupTerm">
          <option value="">Alle Maissorten</option>
          <option value="sehr früh">Sehr frühe Maissorten</option>
          <option value="früh">Frühe Maissorten</option>
          <option value="mittelfrüh">Mittelfrühe Maissorten</option>
          <option value="mittelspät">Mittelspäte Maissorten</option>
          <option value="spät">Späte Maissorten</option>
        </select>
      </div>
      <div class="select-wrapper">
        <select v-model="nutzungsrichtung">
          <option value="">Alle Maissorten</option>
          <option value="Silomais">Silomais</option>
          <option value="Koernermais">Körnermais</option>
          <option value="Biogasmais">Biogasmais</option>
        </select>
      </div>
      <div class="control-buttons-wrapper">
        <div class="control-button">
        <button data-target="searchterm-filter" @click="switchFilter($event)"><span class="button-title">Sorte suchen</span></button>
        </div>
        <div class="control-button">
        <button data-target="sort-filter" @click="switchFilter($event)"><span class="button-title">Sortierung/Filter</span></button>
        </div>
        <div class="control-button">
        <button data-target="comparison-filter" @click="switchFilter($event); toggleComparison();"><span class="button-title">Sorten vergleichen</span></button>
        </div>
      </div>
      <div class="filter-container-wrapper">
        <div class="filter-searchterm-wrapper filter-container" data-active="false" id="searchterm-filter">
          <input type="text" v-model="this.searchTerm" placeholder="Suchbegriff.." />
          <button @click="this.searchTerm = ''"></button>
        </div>
        <div class="filter-outer-wrapper filter-container" data-active="false" id="sort-filter">
          <div class="filter-wrapper">
            <!-- <div class="filter-group-wrapper" data-filter-group="Nutzungsrichtung">
              <div class="filter-group-title">Nutzungsrichtung:</div>
              <div class="single-filter filter-checkbox image-checkbox">
                <input id="filter-Silomais" type="checkbox" data-filter="Silomais" data-img="Silomais" value="1" v-model="selfFilterFelder.Nutzungsrichtung.Value.Silomais.Value" />
                <label for="filter-Silomais" class="filter-title">Silomais</label>
              </div>
              <div class="single-filter filter-checkbox image-checkbox">
                <input id="filter-Körnermais" type="checkbox" data-filter="Koernermais" data-img="Koernermais" value="1" v-model="selfFilterFelder.Nutzungsrichtung.Value.Koernermais.Value" />
                <label for="filter-Körnermais" class="filter-title">Körnermais</label>
              </div>
              <div class="single-filter filter-checkbox image-checkbox">
                <input id="filter-Biogasmais" type="checkbox" data-filter="Biogasmais" data-img="Biogasmais" value="1" v-model="selfFilterFelder.Nutzungsrichtung.Value.Biogasmais.Value" />
                <label for="filter-Biogasmais" class="filter-title">Biogasmais</label>
              </div>
            </div> -->
            <div class="filter-group-wrapper" data-filter-group="Standort">
              <div class="filter-group-title">Standort:</div>
              <div class="single-filter filter-checkbox" :class="{active: selfFilterFelder.Standort.Value.kalt.Value == true}">
                <input id="filter-kalt" type="checkbox" data-filter="kalt" value="1" v-model="selfFilterFelder.Standort.Value.kalt.Value" />
                <label for="filter-kalt" class="filter-title">Kalte Böden</label>
              </div>
              <div class="single-filter filter-checkbox" :class="{active: selfFilterFelder.Standort.Value.mittel.Value == true}">
                <input id="filter-mittel" type="checkbox" data-filter="mittel" value="1" v-model="selfFilterFelder.Standort.Value.mittel.Value" />
                <label for="filter-mittel" class="filter-title">Mittlere Böden</label>
              </div>
              <div class="single-filter filter-checkbox" :class="{active: selfFilterFelder.Standort.Value.trocken.Value == true}">
                <input id="filter-trocken" type="checkbox" data-filter="trocken" value="1" v-model="selfFilterFelder.Standort.Value.trocken.Value" />
                <label for="filter-trocken" class="filter-title">Trockene Böden</label>
              </div>
            </div>
            <div class="filter-group-wrapper" v-show="nutzungsrichtung !== 'Koernermais'">
              <div class="filter-group-title">Silomais Reifegruppe:</div>
              <div class="single-filter filter-select">
                <div class="filter-title">Reife Einstellung:</div>
                <div class="filter-config">
                  <div class="select-wrapper">
                    <select data-filter="Reifegruppe_Silo" v-model="selfFilterFelder.Reifegruppe_Silo.Value" @change="filterResults($event)">
                      <option value="">Alle Maissorten</option>
                      <option value="sehr früh">Sehr frühe Maissorten</option>
                      <option value="früh">Frühe Maissorten</option>
                      <option value="mittelfrüh">Mittelfrühe Maissorten</option>
                      <option value="mittelspät">Mittelspäte Maissorten</option>
                      <option value="spät">Späte Maissorten</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="single-filter filter-range">
                <div class="filter-title">Reifezahl: <span class="range-wrapper" :class="{active: selfFilterFelder.Siloreifezahl.Value[0] !== selfFilterFelder.Siloreifezahl.min || selfFilterFelder.Siloreifezahl.Value[1] !== selfFilterFelder.Siloreifezahl.max}"><span class="mini">{{ selfFilterFelder.Siloreifezahl.Value[0] }}</span> - <span class="maxi">{{ selfFilterFelder.Siloreifezahl.Value[1] }}</span></span></div>
                <div class="filter-config">
                  <Slider data-filter="Siloreifezahl" v-model="selfFilterFelder.Siloreifezahl.Value" v-bind="selfFilterFelder.Siloreifezahl" />
                  <!-- <div class='range-slider' :class="{active: selfFilterFelder.Siloreifezahl.Value[0] !== 150 || selfFilterFelder.Siloreifezahl.Value[1] !== 350 }">
                    <input type="range" min="150" max="350" data-init="150" data-filter="Siloreifezahl" @input="filterResults($event)" v-model.number="selfFilterFelder.Siloreifezahl.Value[0]" />
                    <input type="range" min="150" max="350" data-init="350" data-filter="Siloreifezahl" @input="filterResults($event)" v-model.number="selfFilterFelder.Siloreifezahl.Value[1]" />
                  </div> -->
                </div>
              </div>
            </div>
            <div class="filter-group-wrapper" v-show="nutzungsrichtung !== 'Silomais'">
              <div class="filter-group-title">Körnermais Reifegruppe:</div>
              <div class="single-filter filter-select">
                <div class="filter-title">Reife Einstellung:</div>
                <div class="filter-config">
                  <div class="select-wrapper">
                    <select data-filter="Reifegruppe_Silo" v-model="selfFilterFelder.Reifegruppe_Korn.Value" @change="filterResults($event)">
                      <option value="">Alle Maissorten</option>
                      <option value="sehr früh">Sehr frühe Maissorten</option>
                      <option value="früh">Frühe Maissorten</option>
                      <option value="mittelfrüh">Mittelfrühe Maissorten</option>
                      <option value="mittelspät">Mittelspäte Maissorten</option>
                      <option value="spät">Späte Maissorten</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="single-filter filter-range">
                <div class="filter-title">Reifezahl: <span class="range-wrapper" :class="{active: selfFilterFelder.Koernerreifezahl.Value[0] !== selfFilterFelder.Koernerreifezahl.min || selfFilterFelder.Koernerreifezahl.Value[1] !== selfFilterFelder.Koernerreifezahl.max}"><span class="mini">{{ selfFilterFelder.Koernerreifezahl.Value[0] }}</span> - <span class="maxi">{{ selfFilterFelder.Koernerreifezahl.Value[1] }}</span></span></div>
                <div class="filter-config">
                  <Slider data-filter="Koernerreifezahl" v-model="selfFilterFelder.Koernerreifezahl.Value" v-bind="selfFilterFelder.Koernerreifezahl" />
                  <!-- <div class='range-slider' :class="{active: selfFilterFelder.Koernerreifezahl.Value[0] !== 150 || selfFilterFelder.Koernerreifezahl.Value[1] !== 350 }">
                    <input type="range" min="150" max="350" data-init="150" data-filter="Koernerreifezahl" @input="filterResults($event)" v-model.number="selfFilterFelder.Koernerreifezahl.Value[0]" />
                    <input type="range" min="150" max="350" data-init="350" data-filter="Koernerreifezahl" @input="filterResults($event)" v-model.number="selfFilterFelder.Koernerreifezahl.Value[1]" />
                  </div> -->
                </div>
              </div>
            </div>
            <div class="filter-group-wrapper">
              <!-- <div class="filter-group-title">Filter löschen:</div> -->
              <div class="single-filter full"></div>
              <div class="single-filter">
                <div class="filter-title"></div>
                <div class="filter-config">
                  <button type="button" @click="resetSelfFilterFelder()">Filter löschen</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="comparison-outer-wrapper filter-container" data-active="false" id="comparison-filter">
          <div class="comparison-wrapper">
            <div class="comparison-description">Zum Vergleichen von Sorten wählen Sie die gewünschten Sorten in der Liste an und klicken anschließend auf den Button.</div>
            <div class="comparison-button-wrapper">
              <button type="button" class="comparison-button" @click="cornComparison()" :disabled="compareList !== null && compareList.length == 0">Starten</button>
            </div>
          </div>
        </div>
      </div>
		</div>


		<div class="results-outer-wrapper" v-show="filteredCornData.length == 0 && calcCorn == null && !activeForm">
      <div class="results-compared-props">
        <div>Sorte ({{ maissortenSubset.length }})</div>
        <div>Nutzung</div>
        <div>Reifezahl</div>
      </div>
      <div class="results-inner-wrapper">
        <div class="single-result" :class="{ activeCompare: compareList !== null && compareList.includes(sorte.corn_id)}" v-for="sorte of maissortenSubset" :key="sorte.Sorte" @click="enableCompare ? toggleCompareItem($event, sorte.corn_id) : toggleActive($event)">
          <div class="result-compare-property">
            <div class="result-properties">
              <div class="result-property">
                <div class="property-key">Preis pro Einheit*</div>
                <div class="property-value">
                  <span v-if="sorte.Preis !== null">{{ currency(sorte.Preis) }}</span>
                  <span v-else>-</span>
                  </div>
              </div>
              <div class="result-property">
                <div class="property-key">Beize Standard</div>
                <div class="property-value"><button @click="bestellAnfrage(sorte.Sorte + ' (Beize Standard)')">Bestellanfrage</button></div>
              </div>
              <div class="result-property">
                <div class="property-key">Beize Maisguard<sup>&reg;</sup></div>
                <div class="property-value"><button v-if="sorte.noMAISGUARD == '0'" @click="bestellAnfrage(sorte.Sorte + ' (Beize Maisguard)')">Bestellanfrage</button></div>
              </div>

              <div class="result-property">
                <div class="property-key">Gesamttrockenmasse</div>
                <div class="property-value">
                  <span v-if="sorte.Gesamttrockenmasse !== null && sorte.Gesamttrockenmasse !== '0'">
                    <span v-if="sorte.Koernermais == 1" v-html="calculatePropertyScore('Gesamttrockenmasse', sorte.Gesamttrockenmasse, sorte.Koernerreifezahl)"></span>
                    <span v-else v-html="calculatePropertyScore('Gesamttrockenmasse', sorte.Gesamttrockenmasse, sorte.Koernerreifezahl)"></span>
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Energieertrag</div>
                <div class="property-value">
                  <span v-if="sorte.Energieertrag_nicht_BSA !== null && sorte.Energieertrag_nicht_BSA !== '0'">
                    <span v-if="sorte.Koernermais == 1" v-html="calculatePropertyScore('Energieertrag_nicht_BSA', sorte.Energieertrag_nicht_BSA, sorte.Koernerreifezahl)"></span>
                    <span v-else v-html="calculatePropertyScore('Energieertrag_nicht_BSA', sorte.Energieertrag_nicht_BSA, sorte.Koernerreifezahl)"></span>
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Stärkeertrag</div>
                <div class="property-value">
                  <span v-if="sorte.Staerkeertrag_nicht_BSA !== null && sorte.Staerkeertrag_nicht_BSA !== '0'">
                    <span v-if="sorte.Koernermais == 1" v-html="calculatePropertyScore('Staerkeertrag_nicht_BSA', sorte.Staerkeertrag_nicht_BSA, sorte.Koernerreifezahl)"></span>
                    <span v-else v-html="calculatePropertyScore('Staerkeertrag_nicht_BSA', sorte.Staerkeertrag_nicht_BSA, sorte.Koernerreifezahl)"></span>
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Verdaulichkeit</div>
                <div class="property-value">
                  <span v-if="sorte.Verdaulichkeit !== null && sorte.Verdaulichkeit !== '0'">
                    <span v-if="sorte.Koernermais == 1" v-html="calculatePropertyScore('Verdaulichkeit', sorte.Verdaulichkeit, sorte.Koernerreifezahl)"></span>
                    <span v-else v-html="calculatePropertyScore('Verdaulichkeit', sorte.Verdaulichkeit, sorte.Koernerreifezahl)"></span>
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Kornertrag</div>
                <div class="property-value">
                  <span v-if="sorte.Kornertrag !== null && sorte.Kornertrag !== '0'">
                    <span v-if="sorte.Koernermais == 1" v-html="calculatePropertyScore('Kornertrag', sorte.Kornertrag, sorte.Koernerreifezahl)"></span>
                    <span v-else v-html="calculatePropertyScore('Kornertrag', sorte.Kornertrag, sorte.Koernerreifezahl)"></span>
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Pflanzenlänge</div>
                <div class="property-value">
                  <span v-if="sorte.Pflanzenlaenge !== null && sorte.Pflanzenlaenge !== '0'">
                    <span v-if="sorte.Koernermais == 1" v-html="calculatePropertyScore('Pflanzenlaenge', sorte.Pflanzenlaenge, sorte.Koernerreifezahl)"></span>
                    <span v-else v-html="calculatePropertyScore('Pflanzenlaenge', sorte.Pflanzenlaenge, sorte.Koernerreifezahl)"></span>
                  </span>
                  <span v-else>-</span>
                </div>
              </div>

              <div class="result-headline">Eigenschaften</div>

              <div class="result-property">
                <div class="property-key">Jugendentwicklung</div>
                <div class="property-value">
                  <span v-if="sorte.Jugendentwicklung !== null && sorte.Jugendentwicklung !== ''" v-html="sorte.Jugendentwicklung"></span>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Anfälligk. für Stängelfäule</div>
                <div class="property-value">
                  <span v-if="sorte.Anfaelligk_f_Staengelfaeule !== null && sorte.Anfaelligk_f_Staengelfaeule !== ''" v-html="sorte.Anfaelligk_f_Staengelfaeule"></span>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Standfestigkeit</div>
                <div class="property-value">
                  <span v-if="sorte.Standfestigkeit !== null && sorte.Standfestigkeit !== ''" v-html="sorte.Standfestigkeit"></span>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Neigung zu Bestockung</div>
                <div class="property-value">
                  <span v-if="sorte.Neigung_zu_Bestockung !== null && sorte.Neigung_zu_Bestockung !== ''" v-html="sorte.Neigung_zu_Bestockung"></span>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Stay Green</div>
                <div class="property-value">
                  <span v-if="sorte.Stay_Green !== null && sorte.Stay_Green !== ''" v-html="sorte.Stay_Green"></span>
                  <span v-else>-</span>
                </div>
              </div>

              <div class="result-headline">Bestandesdichte in Pflanzen/m<sup>2</sup></div>

              <div class="result-property">
                <div class="property-key main">Silomais</div>
              </div>
              <div class="result-property">
                <div class="property-key">gute Standorte:</div>
                <div class="property-value">
                  <span v-if="sorte.Bestandesdichte_Sg !== null && sorte.Bestandesdichte_Sg !== ''" v-html="sorte.Bestandesdichte_Sg"></span>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">trockene Standorte:</div>
                <div class="property-value">
                  <span v-if="sorte.Bestandesdichte_St !== null && sorte.Bestandesdichte_St !== ''" v-html="sorte.Bestandesdichte_St"></span>
                  <span v-else>-</span>
                </div>
              </div>

              <div class="result-property">
                <div class="property-key main">Körnermais</div>
              </div>
              <div class="result-property">
                <div class="property-key">gute Standorte:</div>
                <div class="property-value">
                  <span v-if="sorte.Bestandesdichte_Kg !== null && sorte.Bestandesdichte_Kg !== ''" v-html="sorte.Bestandesdichte_Kg"></span>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">trockene Standorte:</div>
                <div class="property-value">
                  <span v-if="sorte.Bestandesdichte_Kt !== null && sorte.Bestandesdichte_Kt !== ''" v-html="sorte.Bestandesdichte_Kt"></span>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Sortenblatt:</div>
                <div class="property-value">
                  <span><a :href="'https://maisscout.de/res/sorts/' + sorte.Sorte.trim() + '.pdf'" target="_blank"><img src="../assets/icons/pdf-icon.svg"></a></span>
                </div>
              </div>


            </div>
            <div class="property-value"><span>{{sorte.Sorte}}<span class="sold-out" v-if="sorte.SorteSub">-ausverkauft-</span></span><img :src="sorte.Logo" /></div>
          </div>
          <div class="result-compare-property">
            <img src="/img/icons/check_silomais.svg" v-if="sorte.Silomais == '1'" id="silopic">
            <img src="/img/icons/check_koernermais.svg" v-if="sorte.Koernermais == '1'" id="kornpic">
            <img src="/img/icons/check_biogasmais.svg" v-if="sorte.Biogasmais == '1'" id="biogaspic">
          </div>
          <div class="result-compare-property">
            <div v-if="sorte.Siloreifezahl_circa">{{sorte.Siloreifezahl_circa}} <div class="green">S</div></div>
            <div v-else-if="sorte.Siloreifezahl">{{sorte.Siloreifezahl}}</div>
            <div v-if="sorte.Koernerreifezahl_circa">{{sorte.Koernerreifezahl_circa}} <div class="green">K</div></div>
            <div v-else-if="sorte.Koernerreifezahl">{{sorte.Koernerreifezahl}}</div>
          </div>
        </div>
      </div>
		</div>
    <div class="corn-comparison-wrapper" v-show="filteredCornData.length > 0 && calcCorn == null && !activeForm">
      <div class="corn-comparison-table">
        <div class="corn-comparison-thead">
          <div class="corn-comparison-tr">
            <div class="corn-comparison-th">Eigenschaft</div>
            <div class="corn-comparison-th" v-for="corn in filteredCornData" v-html="corn.Sorte" :key="corn.corn_id"></div>
          </div>
        </div>
        <div class="corn-comparison-tbody">

          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">Züchter</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" v-html="corn.Zuechter" :key="corn.corn_id"></div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">Nutzungsrichtung</div>
            <div class="corn-comparison-td usage" v-for="corn in filteredCornData" :key="corn.corn_id">
              <img class="usageicon" v-if="corn.Silomais == '1'" src="@/assets/icons/check_silomais.svg" />
              <img class="usageicon" v-if="corn.Koernermais == '1'" src="@/assets/icons/check_koernermais.svg" />
              <img class="usageicon" v-if="corn.Biogasmais == '1'" src="@/assets/icons/check_biogasmais.svg" />
            </div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">Reifezahl</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" :key="corn.corn_id">
            <div v-if="corn.Siloreifezahl_circa">{{corn.Siloreifezahl_circa}} <span class="green">S</span></div>
            <span v-else-if="corn.Siloreifezahl">{{corn.Siloreifezahl}}</span>
            <div v-if="corn.Koernerreifezahl_circa">{{corn.Koernerreifezahl_circa}} <span class="green">K</span></div>
            <span v-else-if="corn.Koernerreifezahl">{{corn.Koernerreifezahl}}</span>
            </div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">Preis*</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" :key="corn.corn_id">
              <span v-if="corn.Preis !== null">{{ currency(corn.Preis) }}</span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td headline">Ertragsaufbau</div>
          </div>
          <!-- Gruppe Ertragsaufbau -->
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">Gesamttrockenmasse</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" :key="corn.corn_id">
                  <span v-if="corn.Gesamttrockenmasse !== null && corn.Gesamttrockenmasse !== '0'">
                    <span v-if="corn.Koernermais == 1" v-html="calculatePropertyScore('Gesamttrockenmasse', corn.Gesamttrockenmasse, corn.Koernerreifezahl)"></span>
                    <span v-else v-html="calculatePropertyScore('Gesamttrockenmasse', corn.Gesamttrockenmasse, corn.Koernerreifezahl)"></span>
                  </span>
                  <span v-else>-</span>
            </div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">Energieertrag</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" :key="corn.corn_id">
              <span v-if="corn.Energieertrag_nicht_BSA !== null && corn.Energieertrag_nicht_BSA !== '0'">
                <span v-if="corn.Koernermais == 1" v-html="calculatePropertyScore('Energieertrag_nicht_BSA', corn.Energieertrag_nicht_BSA, corn.Koernerreifezahl)"></span>
                <span v-else v-html="calculatePropertyScore('Energieertrag_nicht_BSA', corn.Energieertrag_nicht_BSA, corn.Koernerreifezahl)"></span>
              </span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">Stärkeertrag</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" :key="corn.corn_id">
              <span v-if="corn.Staerkeertrag_nicht_BSA !== null && corn.Staerkeertrag_nicht_BSA !== '0'">
                <span v-if="corn.Koernermais == 1" v-html="calculatePropertyScore('Staerkeertrag_nicht_BSA', corn.Staerkeertrag_nicht_BSA, corn.Koernerreifezahl)"></span>
                <span v-else v-html="calculatePropertyScore('Staerkeertrag_nicht_BSA', corn.Staerkeertrag_nicht_BSA, corn.Koernerreifezahl)"></span>
              </span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">Verdaulichkeit</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" :key="corn.corn_id">
              <span v-if="corn.Verdaulichkeit !== null && corn.Verdaulichkeit !== '0'">
                <span v-if="corn.Koernermais == 1" v-html="calculatePropertyScore('Verdaulichkeit', corn.Verdaulichkeit, corn.Koernerreifezahl)"></span>
                <span v-else v-html="calculatePropertyScore('Verdaulichkeit', corn.Verdaulichkeit, corn.Koernerreifezahl)"></span>
              </span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">Kornertrag</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" :key="corn.corn_id">
              <span v-if="corn.Kornertrag !== null && corn.Kornertrag !== '0'">
                <span v-if="corn.Koernermais == 1" v-html="calculatePropertyScore('Kornertrag', corn.Kornertrag, corn.Koernerreifezahl)"></span>
                <span v-else v-html="calculatePropertyScore('Kornertrag', corn.Kornertrag, corn.Koernerreifezahl)"></span>
              </span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">Pflanzenlänge</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" :key="corn.corn_id">
              <span v-if="corn.Pflanzenlaenge !== null && corn.Pflanzenlaenge !== '0'">
                <span v-if="corn.Koernermais == 1" v-html="calculatePropertyScore('Pflanzenlaenge', corn.Pflanzenlaenge, corn.Koernerreifezahl)"></span>
                <span v-else v-html="calculatePropertyScore('Pflanzenlaenge', corn.Pflanzenlaenge, corn.Koernerreifezahl)"></span>
              </span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td headline">Eigenschaften</div>
          </div>
          <!-- Gruppe Eigenschaften -->
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">Jugendentwicklung</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" :key="corn.corn_id">
              <span v-if="corn.Jugendentwicklung !== null && corn.Jugendentwicklung !== ''" v-html="corn.Jugendentwicklung"></span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">Anfgk. für Stängelfäule</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" :key="corn.corn_id">
              <span v-if="corn.Anfaelligk_f_Staengelfaeule !== null && corn.Anfaelligk_f_Staengelfaeule !== ''" v-html="corn.Anfaelligk_f_Staengelfaeule"></span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">Standfestigkeit</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" :key="corn.corn_id">
              <span v-if="corn.Standfestigkeit !== null && corn.Standfestigkeit !== ''" v-html="corn.Standfestigkeit"></span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">Neig. zu Bestockung</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" :key="corn.corn_id">
              <span v-if="corn.Neigung_zu_Bestockung !== null && corn.Neigung_zu_Bestockung !== ''" v-html="corn.Neigung_zu_Bestockung"></span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">Stay Green</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" :key="corn.corn_id">
              <span v-if="corn.Stay_Green !== null && corn.Stay_Green !== ''" v-html="corn.Stay_Green"></span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td headline">Bestandesdichte Silomais (Pflanzen/m<sup>2</sup>)</div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">gute Standorte</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" :key="corn.corn_id">
              <span v-if="corn.Bestandesdichte_Sg !== null" v-html="corn.Bestandesdichte_Sg"></span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">trockene Standorte</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" :key="corn.corn_id">
              <span v-if="corn.Bestandesdichte_St !== null" v-html="corn.Bestandesdichte_St"></span>
              <span v-else>-</span>
            </div>
          </div>
          <!-- Gruppe Bestandsdichte Körnermais (Pflanzen/m<sup>2</sup>) -->
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td headline">Bestandesdichte Körnermais (Pflanzen/m<sup>2</sup>)</div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">gute Standorte</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" :key="corn.corn_id">
              <span v-if="corn.Bestandesdichte_Kg !== null" v-html="corn.Bestandesdichte_Kg"></span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">trockene Standorte</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" :key="corn.corn_id">
              <span v-if="corn.Bestandesdichte_Kt !== null" v-html="corn.Bestandesdichte_Kt"></span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td">Sortenblatt</div>
            <div class="corn-comparison-td infosheet" v-for="corn in filteredCornData" :key="corn.corn_id">
              <span><a :href="'https://maisscout.de/res/sorts/' + corn.Sorte.trim() + '.pdf'" target="_blank"><img src="../assets/icons/pdf-icon.svg"></a></span>
            </div>
          </div>
          <div class="corn-comparison-tr">
            <div class="corn-comparison-td ontop">Saatgutbedarf</div>
            <div class="corn-comparison-td" v-for="corn in filteredCornData" :key="corn.corn_id">
              <button type="button" class="demandcalc" @click="initCalcNeed(corn.corn_id)">Bedarfsrechner</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="demand-calculator-wrapper" v-if="calcCorn !== null">
      <div class="demand-calculator-print" @click="printCalculator()"></div>
      <div class="demand-calculator-close" @click="calcCorn = null"></div>
      <div class="demand-headline">
        Saatgut&shy;bedarfsrechner
      </div>
      <div class="calc-prop green-val">
        <div class="calc-description">
          Empfohlene Bestandesdichte für {{calcCorn.recBestandsCat}} (guter Boden)
        </div>
        <div class="calc-value">
          {{calcCorn.recBestandsDichte}} Pfl./m²
        </div>
      </div>
      <div class="calc-prop interactive">
        <div class="calc-description">
          Geplante Bestandesdichte (Pfl./m2)
        </div>
        <div class="calc-value">
          <div class="decrease"><button type="button" @click="decrementDichte()">-</button></div>
          <div class="val">{{calcCorn.bestandsDichte}}</div>
          <div class="increase"><button type="button" @click="incrementDichte()">+</button></div>
        </div>
      </div>
      <div class="calc-prop interactive">
        <div class="calc-description">
          Feldaufgang (%)
        </div>
        <div class="calc-value">
          <div class="decrease"><button type="button" @click="decrementFeldaufgang()">-</button></div>
          <div class="val">{{ Math.round(calcCorn.feldaufgang * 10000) / 100 }}</div>
          <div class="increase"><button type="button" @click="incrementFeldaufgang()">+</button></div>
        </div>
      </div>
      <div class="calc-prop green-val">
        <div class="calc-description">
          Körner/Einheit
        </div>
        <div class="calc-value">
          50.000 Korn
        </div>
      </div>
      <div class="calc-prop green-val">
        <div class="calc-description">
          Aussaatmenge
        </div>
        <div class="calc-value" v-html="calcCorn.aussaatmenge"></div>
      </div>
      <div class="calc-prop interactive">
        <div class="calc-description">
          Fläche (ha)
        </div>
        <div class="calc-value">
          <div class="decrease"><button type="button" @click="decrementFlaeche()">-</button></div>
          <div class="val">{{calcCorn.flaeche}}</div>
          <div class="increase"><button type="button" @click="incrementFlaeche()">+</button></div>
        </div>
      </div>
      <hr>
      <div class="calc-result red-result">
        <div class="calc-description">
          Saatgutbedarf
        </div>
        <div class="calc-value">
          {{calcCorn.saatgutbedarf}}
        </div>
      </div>
      <div class="calc-result">
        <div class="calc-description">
          Reihenabstand
        </div>
        <div class="calc-value">
          <div class="select-wrapper">
            <select v-model.number="calcCorn.reihenabstand" @change="recalcCorn()">
              <option value="75">75 cm</option>
              <option value="37.5">37,5 cm</option>
              <option value="50">50 cm</option>
            </select>
          </div>
        </div>
      </div>
      <div class="calc-result red-result">
        <div class="calc-description">
          Kornabstand in der Reihe
        </div>
        <div class="calc-value">
          {{ calcCorn.kornabstand}}
        </div>
      </div>
      <div class="disclaimer">
        Wir empfehlen die angestrebte Aussaatstärke mit Hilfe
        des Kornabstandes in der Reihe regelmäßig zu kontrollieren! 
      </div>
    </div>
    <div class="order-form-wrapper" v-if="activeForm">
      <div class="order-form-close" @click="activeForm = false"></div>
      <form action="mailto:info@stroetmann-saat.de" method="get" enctype="text/plain">
        <!-- Übermittelte Werte: -->
        <div v-show="0">
          <input type="hidden" name="subject" :value="mailSubject">
          <textarea id="body" name="body" max-length="1000" :value="mailBody" />
        </div>
        <div class="inputWrapper">
          <label for="lastName">Name*</label>
          <input type="text" id="lastName" required v-model="formData.lastName" >
        </div>
        <div class="inputWrapper">
          <label for="firstName">Vorname*</label>
          <input type="text" id="firstName" required v-model="formData.firstName" >
        </div>
        <div class="inputWrapper">
          <label for="eMail">E-Mail</label>
          <input type="text" id="eMail" v-model="formData.eMail">
        </div>
        <div class="inputWrapper">
          <label for="telephone">Telefon*</label>
          <input type="text" id="telephone" required v-model="formData.phone" >
        </div>
        <div class="inputWrapper">
          <label for="zip">PLZ*</label>
          <input type="text" id="zip" required v-model="formData.zip" >
        </div>
        <div class="inputWrapper">
          <label for="street">Straße, Hausnummer</label>
          <input type="text" id="street" v-model="formData.streetNo" >
        </div>
        <div class="inputWrapper">
          <label for="city">Stadt</label>
          <input type="text" id="city" v-model="formData.city" >
        </div>
        <div class="inputWrapper">
          <label for="order">Bestellung</label>
          <textarea id="order" max-length="1000" v-model="formData.order" />
        </div>
        <div class="inputWrapper">
          <label for="notes">Anmerkungen</label>
          <textarea id="notes" max-length="1000" v-model="formData.notes" />
        </div>
        <div class="inputWrapper">
          <a :href="mailLink" class="button-submit" @click="submitFormData()">Jetzt absenden!</a>
        </div>
      </form>
    </div>

	</div>
</template>

<style src="@vueform/slider/themes/default.css"></style>


<style lang="scss">

body { margin: 0; }

// .maisscout {
//   background-color: #EEEEEE;
//   .app-inner {
//     display: flex; flex-direction: column;
//     height: 100vh;
//   }
// }

.controls-container {
  background-color: #EEEFEE;
  padding: 20px; padding-bottom: 0;
}

.control-buttons-wrapper {
  display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between;
  margin-left: -16px; margin-right: -16px; margin-bottom: 10px;
  .control-button {
    // flex: 0 0 calc(100%/3 - 32px);
    flex: 0 0 calc(100%/3);
    padding-left: 16px; padding-right: 16px;
    button {
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
      background: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
      border: 1px solid #C3C3C3; border-radius: 3px;
      color: #707070;
      display: block;
      font-size: 10px; line-height: 12px;
      padding: 24px 2px 4px;
      position: relative;
      width: 100%;
      &:focus {
        border-color: #E73136;
        outline: none; 
        &::before {
          opacity: 0;
        }
        &::after {
          opacity: 1;
        }
      }
      &::before {
        background-position: top center; background-repeat: no-repeat; background-size: contain;
        content: '';
        display: block;
        position: absolute; top: 6px; left: 50%;
        transform: translateX(-50%);
        width: 18px; height: 18px;
      }
      &::after {
        background-position: top center; background-repeat: no-repeat; background-size: contain;
        content: '';
        display: block;
        position: absolute; top: 6px; left: 50%;
        transform: translateX(-50%);
        width: 18px; height: 18px;
        opacity: 0;
      }
      .button-title { pointer-events: none; }
    }
    &:nth-child(1) button::before { background-image: url("/img/icons/icon_search.svg"); }
    &:nth-child(1) button::after { background-image: url("../assets/icons/lupe-active.svg"); }
    &:nth-child(2) button::before { background-image: url("/img/icons/icon_sort.svg"); }
    &:nth-child(2) button::after { background-image: url("../assets/icons/sortierung-active.svg"); }
    &:nth-child(3) button::before { background-image: url("/img/icons/icon_compare.svg"); }
    &:nth-child(3) button::after { background-image: url("../assets/icons/vergleich-active.svg"); }
  }
}
.filter-searchterm-wrapper {
  padding-left: 20px; padding-right: 20px;
  position: relative;
  input[type=text] {
    margin-top: 20px; margin-bottom: 20px;
    font-size: 18px; line-height: 23px;
    padding: 9px; padding-left: 36px;
    border: 1px solid #C3C3C3;
    border-radius: 3px;
    box-sizing: border-box;
    background: #FFFFFF;
    background-image: url("/img/icons/icon_search.svg"); background-repeat: no-repeat; background-position: top 12px left 9px;
    width: 100%;
    &:focus { outline: none; }
  }
  button {
    background-color: transparent; background-image: url("/img/icons/icon_single_result_less.svg"); background-position: top center; background-repeat: no-repeat; background-size: contain;
    content: "";
    cursor: pointer;
    display: block;
    position: absolute;
    top: 32px;
    right: 32px;
    width: 19px;
    height: 19px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #C3C3C3;
    border-radius: 50%;
    &:focus { outline: none; }
  }
}
.select-wrapper {
  background: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
  height: 40px;
  margin-bottom: 9px;
  position: relative;
  &::after {
    border-top: 3px solid #C3C3C3; border-right: 3px solid #C3C3C3; border-bottom: 0; border-left: 0;
    content: '';
    display: inline-block;
    padding-top: 6px; padding-right: 6px;
    position: absolute; top: 15px; right: 15px;
    transform: rotate(135deg);
  }
  select {
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid #C3C3C3; border-radius: 3px;
    background: transparent;
    font-size: 16px;
    position: absolute; top: 0; left: 0;
    width: 100%; height: 100%;
    padding: 9px 12px;
    &:focus { outline: none; }
  }
}
.filter-outer-wrapper {

  // margin-top: 16px;
  // margin-bottom: 16px;

  .filter-wrapper-title { margin-bottom: 16px; }
  .filter-wrapper {
    display: flex; align-items: center; flex-wrap: wrap;
    // max-height: 240px;
    // overflow-y: scroll;
    .filter-group-wrapper {
      flex: 0 0 100%;
      display: flex; /*align-items: center;*/ flex-wrap: wrap;
      .filter-group-title {
        color: #B5B5B5;
        flex: 0 0 100%;
        font-size: 16px; font-weight: 700; line-height: 22px;
        padding: 9px 20px;
        text-transform: uppercase;
        border-bottom: 1px solid #dedede;
        border-top: 1px solid #dedede;
      }
      &:first-child {
        .filter-group-title {
          border-top: none;
        }
      }
    }
    
    .single-filter {
      flex: 0 0 100%;
      box-sizing: border-box;
      // margin-bottom: 16px;
      &.full { flex-basis: 100% !important; margin-bottom: 16px; }
      &.filter-range {
        margin-bottom: 16px;
      }
      &.filter-checkbox{
        padding: 5px 20px;
        position: relative;
        &.active {
          background-color: #E8F6F5;
        }
        .filter-title { 
          display: block;
          margin-left: 0;
          padding-left: 40px;
          position: relative;
          cursor: pointer;
          // &::before {
          //   // background-color: red;
          //   content: '';
          //   pointer-events: none;
          //   position: absolute; top: 0; left: 0;
          //   width: 30px; height: 30px;
          // }
        }
        input[type=checkbox] {
          --webkit-appearance: none;
          appearance: none;
          cursor: pointer;
          margin: 0;
          position: absolute; top: 5px; left: 20px;
          width: 30px; height: 30px;
          z-index: 1;
          background-image: url("/img/icons/check_inactive.svg");
          background-position: center; background-repeat: no-repeat; background-size: 23px;
          &:checked {
            background-image: url("/img/icons/check_active.svg");
          }
          &:focus {
            outline: none;
          }
        }
      }
      &.image-checkbox {
        input[type=checkbox]{
          &[data-img="Silomais"], &[data-img="Koernermais"], &[data-img="Biogasmais"] {
            filter: grayscale(1);
            &:checked {
              filter:grayscale(0);
            }
            &:focus {
              outline: none;
            }
          }
          &[data-img="Silomais"] {
            background-image: url("/img/icons/check_silomais.svg");
          }
          &[data-img="Koernermais"] {
            background-image: url("/img/icons/check_koernermais.svg");
          }
          &[data-img="Biogasmais"] {
            background-image: url("/img/icons/check_biogasmais.svg");
          }
        }
      }
      .filter-title {
        // margin-bottom: 8px;
        font-size: 16px; line-height: 30px;
        margin-left: 21px;
        user-select: none;
        .range-wrapper {
          font-weight: 700;
          padding-left: 16px;
          &.active{
            color: #E73136;
          }
        }
      }
      .filter-config {
        padding-left: 20px; padding-right: 20px;
        
        button {
          cursor: pointer;
          -webkit-appearance: none;
          appearance: none;
          background: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
          border: 1px solid #C3C3C3; border-radius: 3px;
          color: #E73136;
          display: block;
          font-size: 16px; font-weight: bold; line-height: 20px;
          padding-top: 10px; padding-bottom: 10px;
          position: relative;
          width: 100%;
          margin-bottom: 16px;
          &:focus {
            border-color: #E73136;
            outline: none; 
          }
          &::before {
            background-position: top center; background-repeat: no-repeat; background-size: contain;
            content: '';
            display: block;
            position: absolute; top: 6px; left: 50%;
            transform: translateX(-50%);
            width: 18px; height: 18px;
          }
          .button-title { pointer-events: none; }
        }
      }
    }
  }
  .range-slider {
    width: 100%;
    margin: auto;
    text-align: center;
    position: relative;
    height: 1.5rem;
    input[type=range] {
      position: absolute;
      left: 0;
      top: .5rem;
    }

    input[type=number] {
      border: 1px solid #ddd;
      text-align: center;
      font-size: 1.6em;
      -moz-appearance: textfield;
    }

    input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button { -webkit-appearance: none; }
    input[type=number]:invalid, input[type=number]:out-of-range { border: 2px solid #ff6347; }
    input[type=range] { -webkit-appearance: none; width: 100%; }
    input[type=range]:focus { outline: none; }

    input[type=range]:nth-child(2){
          background-color: transparent;
          &::-webkit-slider-runnable-track { background-color: transparent; }
          &::-moz-range-track { background-color: transparent; }
          &:focus {
            background-color: transparent;
            &::-webkit-slider-runnable-track { background-color: transparent; }
            &::-moz-range-track { background-color: transparent; }
          }
        }

    input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 12px;
      cursor: pointer;
      animate: 0.2s;
      background-image: linear-gradient(to right, #E0DFDF 0, #E0DFDF 2%, #007858 2%, #007858 98%, #E0DFDF 98%, #E0DFDF 100%);
      background-size: 10%;
      border-radius: 1px;
      box-shadow: none;
      border: 0;
    }

    input[type=range]::-webkit-slider-thumb {
      z-index: 2;
      position: relative;
      box-shadow: 0px 0px 0px #000;
      border: 1px solid #C3C3C3;
      height: 30px;
      width: 22px;
      border-radius: 3px;
      background: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -8px;
    }
    &.active {
      input[type=range]::-webkit-slider-runnable-track{
        background-image: linear-gradient(to right, #E0DFDF 0, #E0DFDF 2%, #C11E22 2%, #C11E22 98%, #E0DFDF 98%, #E0DFDF 100%);
      }
    }
  }

}
.comparison-outer-wrapper {
  background-color: #007858;
  color: #FFFFFF;
  // margin-bottom: 16px;

  .comparison-wrapper-title { margin-bottom: 16px; }

  .comparison-wrapper {
    padding: 13px 20px;
    display: flex; align-items:center; justify-content: space-between;
    .comparison-description {
      font-size: 11px; line-height: 16px;
    }
    .comparison-button-wrapper {
      .comparison-button {
        background-color: transparent;
        color: #FFFFFF;
        cursor: pointer;
        border: 1px solid #FFFFFF;
        border-radius: 3px;
        font-size: 14px; line-height: 20px;
        margin-left: 12px;
        padding: 6px 24px;
        text-transform: uppercase;
        transition: background-color .3s ease-in-out, color .3s ease-in-out;
        &:focus {
          outline: none;
        }
        &:hover {
          color: #007858;
          background-color: #FFFFFF;
        }
      }
    }
  }
}

.filter-container-wrapper {
  background-color: #ffffff;
  margin-left: -20px; margin-right: -20px;
  max-height: 240px;
  overflow-y: scroll;
  text-align: left;
  &::-webkit-scrollbar { width: 0; height: 0; }
  .filter-container {
    // display: none;
    height: 0;
    overflow: hidden;
    position: relative;
    transition: height 0.3s ease-in-out;
    // &.comparison-outer-wrapper {
    //   position: absolute; bottom: 0; left: 0; right: 0;
    //   z-index: 2;
    // }
    &#searchterm-filter {
      background-color: #EEEFEE;
    }
    &[data-active="true"]{
      overflow: visible;
      &#searchterm-filter {
        padding-bottom: 20px;
      }
    }
  }
}

.results-outer-wrapper {
  background-color: #ffffff;
  margin: 0 auto;
  // max-width: 414px;
  // height: 100%;
  flex: 1;
  overflow: scroll;
  width: 100%;
  &::-webkit-scrollbar { width: 0; height: 0; }
  .results-compared-props {
    background-color: #58645A;
    color: #ffffff;
    display: flex;
    font-size: 11px; line-height: 12px;
    position: relative;
    text-align: left;
    user-select: none;
    &::before {
      // content: '';
      display: block;
      height: 1px;
      background-color: #ffffff;
      position: absolute; bottom: 0; left: 0; right: 0;
      padding: 9px 12px;
    }
    > div {
      border-bottom: 1px solid #ffffff;
      // flex: 0 0 calc(25% - 24px);
      flex: 0 0 25%;
      padding: 9px 12px;
      position: relative;
      &::before {
        background-color: #ffffff;
        content: '';
        display: block;
        position: absolute; top: 0; left: 0; bottom: 0;
        width: 1px;
      }
      &:first-child {
        // flex: 0 0 calc(50% - 24px);
        flex: 0 0 50%;
        &::before { content: none; }
      }
      &:first-child:last-child {
        // flex-basis: calc(100% - 24px);
        flex-basis: 100%;
      }
      &:last-child {

      }
      &[data-sort="ASC"]{
        &::after {
          // content: '\2191';
        }
      }
      &[data-sort="DESC"]{
        &::after {
          // content: '\2193';
        }
      }
    }
  }
  .results-inner-wrapper {
    .single-result {
      cursor: pointer;
      display: flex;
      border-bottom: 1px solid #DEDEDE;
      position: relative;
      user-select: none;
      .result-title {
        background-color: #58645A;
        padding: 9px 12px;
        text-align: left;
        // flex: 0 0 calc(50% - 24px);
        flex: 0 0 50%;
      }
      .result-properties {
        // display: none;
        // font-size: 1rem;
        background-color: #ffffff;
        position: absolute; top: 100%; left: 0;
        padding-left: 9px;
        width: 100%; height: 0;
        z-index: 1;
        overflow: hidden;
        transition: height .3s ease-in-out;
        .result-property {
          background-color: #E8F6F5;
          border-bottom: 1px solid #DEDEDE;
          color: #1F1F1F;
          display: flex;
          text-align: left;
          .property-key {
            flex: 0 0 60%; 
            padding: 9px 12px;
          }
          .property-value {
            // flex: 0 0 35%;
            font-weight: 700;
            padding: 9px 12px;
            width: 100%;
            /*
            word-break: break-all;
            */
            button {
              cursor: pointer;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              background: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
              border: 1px solid #707070;
              border-radius: 3px;
              color: #707070;
              display: block;
              font-size: 10px;
              line-height: 12px;
              padding: 8px;
              padding-left: 22px;
              position: relative;
              width: 100%;
              z-index: 0;
              pointer-events: all;
              &::before {
                background-position: top center;
                background-repeat: no-repeat;
                background-size: contain;
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 10px;
                width: 18px;
                height: 18px;
                transform: translateY(-50%);
                background-image: url(../assets/icons/bestell-icon.svg);
              }
            }
            img {
              position: relative;
              display: block;
              width: auto;
              height: 32px;
              &::after {
                content: attr(alt);
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                min-height: 100%;
                height: auto;
                width: 100%;
                background-color: #E8F6F5;
                z-index: 1;
              }
            }
          }
        }
        .result-headline {
          border-bottom: 1px solid #DEDEDE;
          color: #B5B5B5;
          font-size: 16px; font-weight: 700; line-height: 24px;
          padding: 3px 12px;
          text-transform: uppercase;
        }
        .result-compare-property {
          flex: 0 0 calc(25% - 24px);
          flex: 0 0 25%;
        }
      }
      &.active {
        
        &::before {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          border-color: transparent #003a2d transparent transparent;
          border-style: solid;
          border-width: 0 9px 9px 0;
          width: 0;
          height: 0;
          z-index: 10;
        }
        .result-title {
          background-color: #007858;
        }
        // .result-properties {
        //   background-color: #ffffff;
        //   display: block;
        //   position: absolute;
        //   top: 100%;
        //   left: 0;
        //   padding-left: 9px;
        //   // width: 150%;
        //   width: calc(100% - 9px);
        //   z-index: 1;
        //   overflow: hidden;
        // }
        .result-compare-property {
          background-color: #007858;
          color: #ffffff;
          &:first-child {
            color: #ffffff;
            &::after {
              background-image: url('/img/icons/icon_single_result_less.svg')
            }
          }
          &:last-child::after {
            border-color: #ffffff;
            transform: translateY(-40%) rotate(-45deg);
          }
          &:first-child:last-child .result-properties { width: 100%; }
        }
      }
      &.activeCompare {
        .result-compare-property {
          &:first-child {
            background-image: linear-gradient(to right, #007858 0%, #007858 100%); background-repeat: no-repeat; background-size: 6px 100%;
          }
        }
      }
      .result-compare-property {
        background-color: #FFF;
        color: #707070;
        cursor: pointer;
        display: flex; /*align-items: center;*/ flex-wrap: wrap;
        // flex: 0 0 calc(25% - 18px);
        flex: 0 0 25%;
        font-size: 11px; line-height: 16px;
        padding: 9px;
        pointer-events: none;
        text-align: left;
        a {
          pointer-events: all;
        }
        .property-value { 
          display: flex;
          align-items: center;
          >span:first-child{
            /*float: left;*/
            // flex-basis: calc(100% - 30px);
            flex-basis: 100%;
            .sold-out { 
              display: block;
              font-size: 11px; line-height: 16px;
            }
          }
          >span:nth-child(2){
            /*float: right;*/
          flex-basis: 30px;
          }
        }
        > div { flex-basis: 100%; }
        &:first-child {
          color: #E73136;
          font-size: 18px; line-height: 23px;
          // flex: 0 0 calc(50% - 24px);
          flex: 0 0 50%;
          > .property-value  img { float: right; max-width: 26px; }
          input[type=checkbox] { display: none; }
          .sold-out { 
            font-size: 11px; line-height: 16px;
          }
          // position: sticky; left: 0;
        }
        &:nth-child(2) {
          img { 
            width: 20px; height: auto; padding: 2px; 
            &#kornpic {
              &:first-child {
                margin-left: 24px;
              }
            }
            &#biogaspic {
              &:first-child {
                margin-left: 48px;
              }
            }
          }
          #silopic + #biogaspic { margin-left: 24px; }
        }
        &:first-child:last-child {
          //only "Sorte"
          // flex-basis: calc(100% - 24px)
          flex-basis: 100%

        }
        &:last-child {
              position: relative;
              &::after {
                background-color: transparent;
                border-top: 3px solid #007858;
                border-right: 3px solid #007858;
                border-bottom: 0;
                border-left: 0;
                padding-top: 8px;
                padding-right: 8px;
                top: 0;
                width: auto;
                height: auto;
                content: '';
                display: block;
                position: absolute; top: 50%; right: 10px;
                transform: translateY(-66%) rotate(135deg);
                transition: all .4s ease-out;
              }
        }
        .green { display: inline-block; font-weight: 700; }
      }
    }
  }
}

.corn-comparison-wrapper {
  background-color: #ffffff;
  margin: 0 auto;
  // max-width: 414px;
  // height: 100%;
  flex: 1;
  overflow: scroll;
  width: 100%;
  position: relative;
  &::-webkit-scrollbar { 
    width: 0; 
    height: 0;
  }
  .corn-comparison-table {
    .corn-comparison-thead {
      position: sticky;
      top: 0;
      z-index:1;
      .corn-comparison-tr {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #DEDEDE;
        .corn-comparison-th {
          flex: 0 0 30%;
          font-size: 11px;
          background-color: #fff;
          padding: 8px 20px;
          color: #E73136;
          &:first-child {
            position: sticky;
            padding: 10px 20px;
            flex-basis: 40%;
            left: 0;
            font-size: 11px;
            text-align: left;
            background-color: #58645A;
            color: #fff;
            font-weight: 700;
          }
        }
      }
    }
    .corn-comparison-tbody {
      .corn-comparison-tr {
        display: flex;
        border-bottom: 1px solid #DEDEDE;
        .corn-comparison-td {
          flex: 0 0 30%;
          padding: 10px 16px;
          font-size: 10px;
          font-weight: 700;
          &:first-child {
            flex-basis: 40%;
          }
          &.ontop {
            z-index: 1;
          }
          .demandcalc {
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
            border: 1px solid #707070;
            border-radius: 3px;
            color: #707070;
            display: block;
            font-size: 10px;
            line-height: 12px;
            padding: 8px;
            position: relative;
            width: 100%;
            z-index: 0;
            &::before {
              background-position: top center;
              background-repeat: no-repeat;
              background-size: contain;
              content: "";
              display: block;
              position: absolute;
              top: 50%;
              left: 4px;
              width: 18px;
              height: 18px;
              transform: translateY(-50%);
              background-image: url(../assets/icons/calc-icon.svg);
            }
            &:focus {
              &::before {
              background-image: url(../assets/icons/calc-icon-active.svg);
              }
            }
          }
          &.infosheet {
            padding: 5px 20px;
          }
          img {
            &.usageicon {
              max-width: 20px;
              height: auto;
              padding: 0 2px;
            }
          }
          &:first-child {
            text-align: left;
            position: sticky;
            left: 0;
            font-size: 10px;
            font-weight: 400;
          }
          &:nth-child(odd){
            background-color: #EEEEEE;
          }
          &.headline {
            flex: 0 0 auto; 
            background-color: #fff;
            font-size: 11px;
            font-weight: 700;
            color: #B5B5B5;
            text-transform: uppercase;
          }
          &.usage {
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}

.demand-calculator-wrapper {
  background-color: #EEEFEE;
  padding-left: 20px; padding-right: 20px;
  position: relative;
  .demand-calculator-close {
    background-image: url("/img/icons/icon_single_result_less.svg");
    cursor: pointer;
    position: absolute; top: 12px; right: 20px;
    width: 19px; height: 19px;
  }
  .demand-calculator-print {
    background-color: #ffffff; background-image: url("/img/icons/icon_print.svg"); background-size: 100%;
    border-radius: 50%;
    display: none;
    cursor: pointer;
    position: absolute; top: 12px; right: 48px;
    width: 19px; height: 19px;
  }
  .demand-headline {
    font-size: 22px;
    line-height: 28px;
    font-weight: 700;
    text-align: left;
    padding: 10px 20px 20px 0;
    text-transform: uppercase;
    
  }
  hr {
    margin-bottom: 10px;
  }
  select {
    font-family: "Spartan", sans-serif;
  }
  .disclaimer {
    font-size: 11px;
    line-height: 16px;
    text-align: left;
    margin-bottom: 20px;
  }
  .calc-prop, .calc-result {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    &.interactive {
      .calc-value {
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        .val {
          margin-top: 4px;
        }
        button {
          border-radius: 50%;
          border: 1px solid #696969;
          cursor: pointer;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
          color: #707070;
          display: flex;
          font-size: 24px;
          line-height: 1;
          width: 24px;
          height: 24px;
          padding: 0;
          align-items: center;
          justify-content: center;
          margin-top: none;
          user-select: none;
          &:focus {
            outline: none;
          }
        }
      }
    }
    &.red-result {
      color: #E73136;
      .calc-value {
        font-weight: 700;
      }
    }
    &.green-val {
      .calc-value {
        color: #007858;
      }
    }
    .calc-description {
      flex: 0 0 calc(100% - 145px);
      max-width: calc(100% - 145px);
      text-align: left;
      font-size: 14px;
    }
    .calc-value {
      flex: 0 0 145px;
      max-width: 145px;
      width: 100%;
      text-align: right;
      font-size: 18px; 
    }
  }
}

.order-form-wrapper {
  text-align: left;
  background-color: #EEEFEE;
  padding-left: 20px; 
  padding-right: 20px;
  position: relative;
  .order-form-close {
    background-image: url("/img/icons/icon_single_result_less.svg");
    cursor: pointer;
    position: absolute; top: 12px; right: 20px;
    width: 19px; height: 19px;
  }
  form {
    margin-top: 48px;
    .inputWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      label {
        flex-basis: 50%;
      }
      input, textarea {
        flex-basis: 50%;
        font-size: 18px;
        line-height: 23px;
        padding: 9px;
        border: 1px solid #C3C3C3;
        border-radius: 3px;
        box-sizing: border-box;
        background: #FFFFFF;
        width: 100%;
      }
      .button-submit {
        background-color: transparent;
        color: #FFFFFF;
        cursor: pointer;
        border: 1px solid #FFFFFF;
        border-radius: 3px;
        font-size: 14px; line-height: 20px;
        margin-left: 12px;
        padding: 6px 24px;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        transition: background-color .3s ease-in-out, color .3s ease-in-out;
        background-color: #007858;
        width: 50%;
        &:focus {
          outline: none;
        }
        &:hover {
          color: #007858;
          background-color: #FFFFFF;
        }
      }
      &:last-child {
        justify-content: flex-end;
      }
    }
  }
}

// @media screen and(min-width: 769px) {
//   .filter-outer-wrapper {
//     .filter-wrapper {
//       .single-filter:not(.filter-range) {
//         flex: 0 0 50%
//       }
//     }
//   }
// }

@media screen and(min-width: 961px) {
  .filter-outer-wrapper {
    .filter-wrapper {
      .single-filter {
        flex: 0 0 calc(100% / 3);
        &.filter-range, &.filter-select {
          flex: 0 0 50%;
        }
      }
    }
  }
}

@media screen and(min-width: 1025px) {
  .demand-calculator-wrapper .demand-calculator-print { display: block; }
}

// @media screen and(min-width: 1366px) {
//   .filter-outer-wrapper {
//     .filter-wrapper {
//       .single-filter {
//         flex: 0 0 25%
//       }
//     }
//   }
// }

@media print {
  .controls-container, .calc-value button, .demand-calculator-close, .demand-calculator-print {
    display: none !important;
  }
  .calc-value { justify-content: flex-end !important; }
  .select-wrapper {
    background: none;
    &::after { content: none !important; }
    select {
      border-color: transparent !important;
      direction: rtl !important;
      padding-right: 0 !important;
      text-align: right !important;
      option { direction: ltr !important; }
    }

  }
}

* { box-sizing: border-box; }
button { touch-action: manipulation; }

</style>

<script>
import axios from 'axios'
import lodash from 'lodash'
import Slider from '@vueform/slider'

export default {
    name: 'Maisscout',
    components: { Slider },
		data(){
			return {
        enableCompare: false,
        activeForm: false,
        formData: {
          lastName: '',
          firstName: '',
          eMail: '',
          phone: '',
          zip: '',
          streetNo: '',
          city: '',
          order: '',
          notes: ''
        },
        calcCorn: null,
        compareList: null,
        maissorten: [],
        searchTerm: '',
        groupTerm: '',
        nutzungsrichtung: '',
        cornData: [],
        filteredCornData: [],
        //weiter machen
        selfFilterFelder: {
          "Standort": {"Type": "groupcheck", "Value": {
            "kalt": {"Type": "check", "Value": false },
            "mittel": {"Type": "check", "Value": false },
            "trocken": {"Type": "check", "Value": false }
          }},
          "Nutzungsrichtung": {"Type": "groupcheck", "Value": {
            "Silomais": {"Type": "check", "Value": false },
            "Koernermais": {"Type": "check", "Value": false },
            "Biogasmais": {"Type": "check", "Value": false }
          }},
          "Siloreifezahl": {"Type": "range", "Value": [150, 350], min: 150, max: 350, tooltips: false },
          "Koernerreifezahl": {"Type": "range", "Value": [150, 350], min: 150, max: 350, tooltips: false },
          "Reifegruppe_Korn": {"Type": "select", "Value": ""},
          "Reifegruppe_Silo": {"Type": "select", "Value": ""},
        },
        origSelfFilterFelder: {
          "Standort": {"Type": "groupcheck", "Value": {
            "kalt": {"Type": "check", "Value": false },
            "mittel": {"Type": "check", "Value": false },
            "trocken": {"Type": "check", "Value": false }
          }},
          "Nutzungsrichtung": {"Type": "groupcheck", "Value": {
            "Silomais": {"Type": "check", "Value": false },
            "Koernermais": {"Type": "check", "Value": false },
            "Biogasmais": {"Type": "check", "Value": false }
          }},
          "Siloreifezahl": {"Type": "range", "Value": [150, 350], min: 150, max: 350, tooltips: false },
          "Koernerreifezahl": {"Type": "range", "Value": [150, 350], min: 150, max: 350, tooltips: false },
          "Reifegruppe_Korn": {"Type": "select", "Value": ""},
          "Reifegruppe_Silo": {"Type": "select", "Value": ""},
        },
        maxData: {
          "Gesamttrockenmasse": {
            "frueh": null,
            "mittel": null,
            "spaet": null
          },
          "Energieertrag_nicht_BSA": {
            "frueh": null,
            "mittel": null,
            "spaet": null
          },
          "Staerkeertrag_nicht_BSA": {
            "frueh": null,
            "mittel": null,
            "spaet": null
          },
          "Verdaulichkeit": {
            "frueh": null,
            "mittel": null,
            "spaet": null
          },
          "Kornertrag": {
            "frueh": null,
            "mittel": null,
            "spaet": null
          },
          "Pflanzenlaenge": {
            "frueh": null,
            "mittel": null,
            "spaet": null
          },
        }
        // selfFilterFelder: {}
			}
		},
    computed: {
      maissortenSubset: {
        get() {
          let subset = this.maissorten
          // let subset = this.cornData
          // subset.splice(10)
          
          if(this.searchTerm !== '') subset = subset.filter(sorte => {
            if(sorte.Sorte.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1) return sorte
          })

          if(this.groupTerm !== '') subset = subset.filter(sorte => {
            if(sorte.Reifegruppe_Korn == this.groupTerm || sorte.Reifegruppe_Silo == this.groupTerm) return sorte
          })

          if(this.nutzungsrichtung !== '') subset = subset.filter(sorte => {
            if(sorte[this.nutzungsrichtung] == "1") return sorte
          })

          const selfFF = this.selfFilterFelder
          
          Object.keys(selfFF).forEach(function(filterFeld){
            switch(selfFF[filterFeld].Type){

              case 'groupcheck':
                // if(selfFF[filterFeld].Type == 'groupcheck'){
                //   let check = false
                // }

                if(Object.keys(selfFF[filterFeld].Value).filter(subkey => { return (selfFF[filterFeld].Value[subkey].Value == true) }).length > 0){
                  //eins angehakt
                  const checkedFilters = Object.keys(selfFF[filterFeld].Value).filter(subkey => { return (selfFF[filterFeld].Value[subkey].Value == true) })
                  
                  
                  subset = subset.filter(sorte => {
                    let staysIn = true
                    checkedFilters.forEach(function(checkedFilter){
                      if(sorte[checkedFilter] != "1") staysIn = false
                    })

                    if(staysIn) return sorte;
                    
                    // if(){

                    // }
                  })
                }

                // if(selfFF.filter)

                // Object.keys(selfFF[filterFeld].Value).forEach(function(groupcheck){


                //     if(selfFF[filterFeld].Value[groupcheck].Value == true){
                //       subset = subset.filter(sorte => {
                //         return sorte
                //         // if(){

                //         // }
                //       })
                //     }
                // })

              break;
              case 'check':
                if(selfFF[filterFeld].Value == true){
                  subset = subset.filter(sorte => {
                    
                    if(sorte[filterFeld] == "1") return sorte
                  })
                }
              break;

              case 'range':
                subset = subset.filter(sorte => {


                  if(selfFF["Siloreifezahl"].Value[0] <= parseInt(sorte["Siloreifezahl"]) && parseInt(sorte["Siloreifezahl"]) <= selfFF["Siloreifezahl"].Value[1]) return sorte
                  else if(selfFF["Koernerreifezahl"].Value[0] <= parseInt(sorte["Koernerreifezahl"]) && parseInt(sorte["Koernerreifezahl"]) <= selfFF["Koernerreifezahl"].Value[1]) return sorte



                  // if(isNaN(parseInt(sorte[filterFeld]))) { 
                  //   return sorte
                  // }
                  // else if(selfFF[filterFeld].Value[0] <= parseInt(sorte[filterFeld]) && parseInt(sorte[filterFeld]) <= selfFF[filterFeld].Value[1])
                  // {
                  //   return sorte
                  // }
                })
              break;

              case 'select':
                if(selfFF[filterFeld].Value !== "")
                subset = subset.filter(sorte => {

                  if(sorte[filterFeld] === selfFF[filterFeld].Value) return sorte
                })

              break;
            }
          })


          // console.log(subset)
          return subset
          // return this.maissorten
        }
      },
      mailSubject: {
        get(){
          return "MAISSCOUT Bestellung"
        }
      },
      mailBody: {
        get(){
          let ret = "Sehr geehrte Damen und Herren, \r\n\r\n" + 
          this.formData.order + "\r\n" +
          "Vorname: " + this.formData.firstName + "\r\n" + 
          "Nachname: " + this.formData.lastName + "\r\n" + 
          "E-Mail: " + this.formData.eMail + "\r\n" + 
          "Telefon: " + this.formData.phone + "\r\n" + 
          "PLZ: " + this.formData.zip + "\r\n" + 
          "Stadt: " + this.formData.city + "\r\n" + 
          "Anmerkungen: " + this.formData.notes + "\r\n"

          ret = encodeURI(ret)
          return ret
        }
      },
      mailLink: {
        get(){
          const ret = "mailto:info@stroetmann.de?subject=" + this.mailSubject + "&body=" + this.mailBody
          return ret
        }
      }
    },
		methods: {

      calculatePropertyScore(eigenschaft, punktzahl, reifezahl){
        let ret = '<strong>-</strong>'
        if(reifezahl<=220){
          //früh
          if(punktzahl != 0)
          if(5 + punktzahl - this.maxData[eigenschaft].frueh[eigenschaft] > 0) {
            const korrigiertePunktzahl = 5 + punktzahl - this.maxData[eigenschaft].frueh[eigenschaft]
            ret = '<strong>'
            for(let i = 0; i < korrigiertePunktzahl; i++){
              ret += '&#9679;'
            }
            ret += '</strong>'
          }
          else ret = '<strong>&#9679;</strong>'
        }
        else if(reifezahl > 220 && reifezahl <= 250){
          //mittel
          if(punktzahl != 0)
          if(5 + punktzahl - this.maxData[eigenschaft].mittel[eigenschaft] > 0) {
            const korrigiertePunktzahl = 5 + punktzahl - this.maxData[eigenschaft].mittel[eigenschaft]
            ret = '<strong>'
            for(let i = 0; i < korrigiertePunktzahl; i++){
              ret += '&#9679;'
            }
            ret += '</strong>'
          }
          else ret = '<strong>&#9679;</strong>'
        }
        else {
          //spät
          if(punktzahl != 0)
          if(5 + punktzahl - this.maxData[eigenschaft].spaet[eigenschaft] > 0) {
            const korrigiertePunktzahl = 5 + punktzahl - this.maxData[eigenschaft].spaet[eigenschaft]
            ret = '<strong>'
            for(let i = 0; i < korrigiertePunktzahl; i++){
              ret += '&#9679;'
            }
            ret += '</strong>'
          }
          else ret = '<strong>&#9679;</strong>'
        }
        return ret
        // return 'Hier kommt Funktion'
      },

      currency(preis){
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(preis) 
      },

      resetSelfFilterFelder(){
        this.selfFilterFelder = lodash.cloneDeep(this.origSelfFilterFelder)
      },


			switchFilter(event) {
				
        const targetId = event.target.dataset.target
        const targetEl = document.getElementById(targetId)
        const targetActive = targetEl.dataset.active
        document.querySelectorAll('.filter-container').forEach(function(filCon){
          filCon.dataset.active = false;
          filCon.style.height = "0px"
        })
        if(this.enableCompare) this.toggleComparison()
        targetEl.dataset.active = (targetActive == true || targetActive == "true") ? false : true;
        targetEl.style.height = (targetActive == true || targetActive == "true") ? "0px" : Math.min(240, targetEl.scrollHeight) + "px";
        if(targetActive == true || targetActive == "true") event.target.blur()
        // if(targetActive !== true && targetActive !== "true") window.scrollTo({top: document.body.scrollHeight, left: 0, behavior: 'smooth'})
			},

      
      filterResults(event){
        //set min/max for changed filter
        // console.log(event)
        const filterInputs = document.querySelectorAll('[data-filter="' + event.target.dataset.filter + '"]')
        if(filterInputs.length > 1){
          if(event.target == filterInputs[0]){
            if(parseInt(filterInputs[0].value) >= parseInt(filterInputs[1].value)){
              filterInputs[0].value = Math.max(parseInt(filterInputs[1].value - 1), parseInt(filterInputs[0].dataset.init))
            }
            else if(parseInt(filterInputs[0].value) < parseInt(filterInputs[0].dataset.init)){
              filterInputs[0].value = filterInputs[0].dataset.init
            }
          }
          else if(event.target == filterInputs[1]){
            if(parseInt(filterInputs[1].value) <= parseInt(filterInputs[0].value)){
              filterInputs[1].value = Math.min(parseInt(filterInputs[0].value + 1), parseInt(filterInputs[1].dataset.init))
            }
            else if(parseInt(filterInputs[1].value) > parseInt(filterInputs[1].dataset.init)){
              filterInputs[1].value = filterInputs[1].dataset.init
            }
          }
        }

      //Filter
      // const newFilterFelder = this.selfFilterFelder;

      // filterInputs.forEach(function(inputfield){
      //   const filterIndex = Array.prototype.indexOf.call(inputfield.parentNode.childNodes, inputfield);
      //   if(inputfield.dataset.init !== inputfield.value){
      //     if(typeof newFilterFelder[inputfield.dataset.filter] === 'undefined') newFilterFelder[inputfield.dataset.filter] = []
      //     newFilterFelder[inputfield.dataset.filter][filterIndex] = inputfield.value
      //   }
      //   else {
      //     if(typeof newFilterFelder[inputfield.dataset.filter] === 'undefined') newFilterFelder[inputfield.dataset.filter] = []
      //     newFilterFelder[inputfield.dataset.filter][filterIndex] = inputfield.dataset.init
      //   }
      // })
      // this.selfFilterFelder = newFilterFelder

      // Object.values(this.felder).forEach(function(filterFeld){
      //   // if(filterFeld.comparing) 
      //   document.querySelectorAll('[data-filter="' + filterFeld.Key+ '"]').forEach(function(inputfield, idx){
      //     if(typeof inputfield.dataset.init !== 'undefined'){
      //       //input fields
      //       if(inputfield.dataset.init !== inputfield.value){
      //         selfFilterFelder[filterFeld.Key].selectedFilter[idx] = inputfield.value
      //         // this.filterFelder[filterFeld.Key][idx] = inputfield.value
      //       }
      //     }
      //     else {
      //       //select
      //       if(inputfield.value !== '') selfFilterFelder[filterFeld.Key].selectedFilter[idx] = inputfield.value
      //       else selfFilterFelder[filterFeld.Key].selectedFilter[idx] = ''
      //     }
      //   })
      // })
      // this.selfFilterFelder = selfFilterFelder

      // return
      },
      toggleActive(event){
        // console.log(event)
        if(event.target.nodeName === "IMG" || event.target.nodeName === "A") return false
        if(event.target.classList.contains('active')){
          event.target.children[0].children[0].style.height = "0px"
          setTimeout(function(){
            event.target.classList.remove('active')
          }, 300)
        }
        else {
          document.querySelectorAll('.single-result').forEach(function(singRes){
            singRes.children[0].children[0].style.height = "0px"
            setTimeout(function(){
              singRes.classList.remove('active')
            }, 300)
          })
          event.target.children[0].children[0].style.height = event.target.children[0].children[0].scrollHeight + "px";
          setTimeout(function(){
            event.target.classList.add('active')
          }, 300)
        }
      },
      toggleComparison(){
        this.compareList = (this.enableCompare) ? null : []
        if(this.enableCompare) this.filteredCornData = []
        this.enableCompare = !this.enableCompare
        // console.log(this.compareList)
        // console.log(this.enableCompare)
      },
      toggleCompareItem(event, cornId){
        if(this.compareList.includes(cornId)) { this.compareList.splice(this.compareList.indexOf(cornId), 1)}
        else this.compareList.push(cornId)
      },
      cornComparison(){
        // console.log(this.maissorten);
        // console.log(this.compareList);
        this.filteredCornData = this.maissorten.filter(corn => { if(this.compareList.includes(corn.corn_id)) return corn })
        document.getElementById('comparison-filter').dataset.active = false
        document.getElementById('comparison-filter').style.height = "0px"
        // console.log(this.filteredCornData)
      },
      bestellAnfrage(name){
        if(!this.activeForm) this.formData.order = "Ich möchte " + name + " bestellen."
        this.activeForm = !this.activeForm
      },
      submitFormData(){
        this.bestellAnfrage()
      },
      initCalcNeed(cornId){
        const newCorn = this.maissorten.filter(corn => { if(corn.corn_id == cornId) return corn })[0]
        // console.log(newCorn)

        const calcCornData = {
          aussaatmenge: 0, // Aussaatmenge => Berechnet
          saatgutbedarf: 0, // Saatgutbedarf => Berechnet
          kornabstand: 0, // Kornabstand in der Reihe => Berechnet

          bestandsDichte: 9, // [7, 15] Geplante Bestandesdichte (Pfl./m2) => verstellbar
          recBestandsDichte: 0, // Empfohlene Bestandesdichte für Silonutzung (guter Boden) => ergibt sich
          recBestandsCat: 'Silonutzung', // Empfohlene Bestandesdichte für Silonutzung (guter Boden) => ergibt sich 
          feldaufgang: .9, // Feldaufgang (%) [0.9, 1] => verstellbar
          flaeche: 1, // Fläche (ha) => verstellbar
          reihenabstand: 75, // Reihenabstand
        }

        
        if(newCorn.Bestandesdichte_Kg && newCorn.Bestandesdichte_Sg) {
          calcCornData.recBestandsDichte = (parseFloat(newCorn.Bestandesdichte_Kg) > parseFloat(newCorn.Bestandesdichte_Sg)) ? parseFloat(newCorn.Bestandesdichte_Kg) : parseFloat(newCorn.Bestandesdichte_Sg)
          calcCornData.bestandsDichte = (parseFloat(newCorn.Bestandesdichte_Kg) > parseFloat(newCorn.Bestandesdichte_Sg)) ? parseFloat(newCorn.Bestandesdichte_Kg) : parseFloat(newCorn.Bestandesdichte_Sg)
          calcCornData.recBestandsCat = (parseFloat(newCorn.Bestandesdichte_Kg) > parseFloat(newCorn.Bestandesdichte_Sg)) ? 'Körnernutzung' : 'Silonutzung'
        }
        else if(newCorn.Bestandesdichte_Kg) {
          calcCornData.recBestandsDichte = parseFloat(newCorn.Bestandesdichte_Kg)
          calcCornData.bestandsDichte = parseFloat(newCorn.Bestandesdichte_Kg)
          calcCornData.recBestandsCat = 'Körnernutzung'
        }
        else {
          calcCornData.recBestandsDichte = parseFloat(newCorn.Bestandesdichte_Sg)
          calcCornData.bestandsDichte = parseFloat(newCorn.Bestandesdichte_Sg)
        }

        this.calcCorn = calcCornData


        calcCornData.aussaatmenge = (calcCornData.bestandsDichte * 10000) * ( 1 + (1 - calcCornData.feldaufgang )) / 50000
        calcCornData.aussaatmenge = Math.round(calcCornData.aussaatmenge * 100) / 100
        calcCornData.saatgutbedarf = Math.round(calcCornData.aussaatmenge * calcCornData.flaeche * 100) / 100
        calcCornData.kornabstand = 1 / calcCornData.reihenabstand / calcCornData.bestandsDichte * 10000 * calcCornData.feldaufgang
        calcCornData.kornabstand = Math.round(calcCornData.kornabstand * 10) / 10
        

        // console.log(newCorn.aussaatmenge)
        // console.log(newCorn.saatgutbedarf)
        // console.log(newCorn.kornabstand)
        // console.log(newCorn.recBestandsCat)



      },
      decrementFlaeche(){
        this.calcCorn.flaeche = Math.max(0, this.calcCorn.flaeche - 1)
        this.recalcCorn()
      },
      incrementFlaeche(){
        this.calcCorn.flaeche++
        this.recalcCorn()
      },
      decrementDichte(){
        this.calcCorn.bestandsDichte = Math.max(7, this.calcCorn.bestandsDichte - 0.5)
        this.recalcCorn()
      },
      incrementDichte(){
        this.calcCorn.bestandsDichte = Math.min(15, this.calcCorn.bestandsDichte + 0.5)
        this.recalcCorn()
      },
      decrementFeldaufgang(){
        this.calcCorn.feldaufgang = Math.max(.9, this.calcCorn.feldaufgang - .01)
        this.recalcCorn()
      },
      incrementFeldaufgang(){
        this.calcCorn.feldaufgang = Math.min(1, this.calcCorn.feldaufgang + .01)
        this.recalcCorn()
      },
      recalcCorn(){
        this.calcCorn.aussaatmenge = (this.calcCorn.bestandsDichte * 10000) * ( 1 + (1 - this.calcCorn.feldaufgang )) / 50000
        this.calcCorn.aussaatmenge = Math.round(this.calcCorn.aussaatmenge * 100) / 100
        this.calcCorn.saatgutbedarf = Math.round(this.calcCorn.aussaatmenge * this.calcCorn.flaeche * 100) / 100
        this.calcCorn.kornabstand = 1 / this.calcCorn.reihenabstand / this.calcCorn.bestandsDichte * 10000 * this.calcCorn.feldaufgang
        this.calcCorn.kornabstand = Math.round(this.calcCorn.kornabstand * 10) / 10
      },
      printCalculator(){
        window.print()
      }
		},
    watch: {
      
      nutzungsrichtung(newValue){
        if(newValue === 'Silomais'){
          this.selfFilterFelder["Reifegruppe_Korn"].Value = ""
          this.selfFilterFelder["Koernerreifezahl"].Value = [150, 350]
        }
        else if(newValue === 'Koernermais') {
          this.selfFilterFelder["Reifegruppe_Korn"].Value = ""
          this.selfFilterFelder["Siloreifezahl"].Value = [150, 350]
        }

      }
    },
		created() {
      
      // axios.get('https://portal.stroetmann.de/kupoapi/mais/corn_data.json')
      axios.get('https://stroetmann-saat.de/corn_data.json')
      // axios.get('https://stroetmann-saat.de/utility/?tx_stroetmannext_utility[action]=getCornData&tx_stroetmannext_utility[controller]=LsvMais')
      .then(response => {
        // console.log(response.data)
        response.data.forEach((single) => {
          single.SorteSub = (single.Sorte.includes('-ausverkauft-') || single.Sorte.includes('-ausverk.-'))
          if(single.Sorte.includes('-ausverkauft-') || single.Sorte.includes('-ausverk.-')) {
            single.Sorte = single.Sorte.replace('-ausverkauft-', '')
            single.Sorte = single.Sorte.replace('-ausverk.-', '')
          }
          single.Logo = (single.Eigensorte == 'LS') ? 'https://maisscout.de/res/stroetmann.png' : 'https://maisscout.de/res/' + single.Eigensorte.replaceAll(' ', '_') + '.png'
					this.maissorten.push(single);
        })
        this.maxData.Gesamttrockenmasse.frueh = this.maissorten.reduce((max, sorte) => {
          return (sorte.Siloreifezahl <=220 && sorte.Gesamttrockenmasse > max.Gesamttrockenmasse) ? sorte : max
          })
        this.maxData.Gesamttrockenmasse.mittel = this.maissorten.reduce((max, sorte) => {
          return (sorte.Siloreifezahl > 220 && sorte.Siloreifezahl <= 250 && sorte.Gesamttrockenmasse > max.Gesamttrockenmasse) ? sorte : max
          })
        this.maxData.Gesamttrockenmasse.spaet = this.maissorten.reduce((max, sorte) => {
          return (sorte.Siloreifezahl > 250 && sorte.Gesamttrockenmasse > max.Gesamttrockenmasse) ? sorte : max
          })

        this.maxData.Energieertrag_nicht_BSA.frueh = this.maissorten.reduce((max, sorte) => {
          return (sorte.Siloreifezahl <=220 && sorte.Energieertrag_nicht_BSA > max.Energieertrag_nicht_BSA) ? sorte : max
          })
        this.maxData.Energieertrag_nicht_BSA.mittel = this.maissorten.reduce((max, sorte) => {
          return (sorte.Siloreifezahl > 220 && sorte.Siloreifezahl <= 250 && sorte.Energieertrag_nicht_BSA > max.Energieertrag_nicht_BSA) ? sorte : max
          })
        this.maxData.Energieertrag_nicht_BSA.spaet = this.maissorten.reduce((max, sorte) => {
          return (sorte.Siloreifezahl > 250 && sorte.Energieertrag_nicht_BSA > max.Energieertrag_nicht_BSA) ? sorte : max
          })

        this.maxData.Staerkeertrag_nicht_BSA.frueh = this.maissorten.reduce((max, sorte) => {
          return (sorte.Siloreifezahl <=220 && sorte.Staerkeertrag_nicht_BSA > max.Staerkeertrag_nicht_BSA) ? sorte : max
          })
        this.maxData.Staerkeertrag_nicht_BSA.mittel = this.maissorten.reduce((max, sorte) => {
          return (sorte.Siloreifezahl > 220 && sorte.Siloreifezahl <= 250 && sorte.Staerkeertrag_nicht_BSA > max.Staerkeertrag_nicht_BSA) ? sorte : max
          })
        this.maxData.Staerkeertrag_nicht_BSA.spaet = this.maissorten.reduce((max, sorte) => {
          return (sorte.Siloreifezahl > 250 && sorte.Staerkeertrag_nicht_BSA > max.Staerkeertrag_nicht_BSA) ? sorte : max
          })

        this.maxData.Verdaulichkeit.frueh = this.maissorten.reduce((max, sorte) => {
          return (sorte.Siloreifezahl <=220 && sorte.Verdaulichkeit > max.Verdaulichkeit) ? sorte : max
          })
        this.maxData.Verdaulichkeit.mittel = this.maissorten.reduce((max, sorte) => {
          return (sorte.Siloreifezahl > 220 && sorte.Siloreifezahl <= 250 && sorte.Verdaulichkeit > max.Verdaulichkeit) ? sorte : max
          })
        this.maxData.Verdaulichkeit.spaet = this.maissorten.reduce((max, sorte) => {
          return (sorte.Siloreifezahl > 250 && sorte.Verdaulichkeit > max.Verdaulichkeit) ? sorte : max
          })

        this.maxData.Kornertrag.frueh = this.maissorten.reduce((max, sorte) => {
          return (sorte.Koernerreifezahl <= 220 && sorte.Kornertrag > max.Kornertrag) ? sorte : max
          })
        this.maxData.Kornertrag.mittel = this.maissorten.reduce((max, sorte) => {
          return (sorte.Koernerreifezahl > 220 && sorte.Koernerreifezahl <= 250 && sorte.Kornertrag > max.Kornertrag) ? sorte : max
          })
        this.maxData.Kornertrag.spaet = this.maissorten.reduce((max, sorte) => {
          return (sorte.Koernerreifezahl > 250 && sorte.Kornertrag > max.Kornertrag) ? sorte : max
          })

        this.maxData.Pflanzenlaenge.frueh = this.maissorten.reduce((max, sorte) => {
          return (sorte.Pflanzenlaenge > max.Pflanzenlaenge) ? sorte : max
          })
        this.maxData.Pflanzenlaenge.mittel = this.maissorten.reduce((max, sorte) => {
          return (sorte.Pflanzenlaenge > max.Pflanzenlaenge) ? sorte : max
          })
        this.maxData.Pflanzenlaenge.spaet = this.maissorten.reduce((max, sorte) => {
          return (sorte.Pflanzenlaenge > max.Pflanzenlaenge) ? sorte : max
          })

          // console.log(this.maxData.Kornertrag.frueh)
          // console.log(this.maxData.Kornertrag.mittel)
          // console.log(this.maxData.Kornertrag.spaet)
          // vorher: <span v-if="sorte.Silomais == 1 || (sorte.Silomais !== 1 && sorte.Biogasmais == 1)" v-html="calculatePropertyScore('Gesamttrockenmasse', sorte.Gesamttrockenmasse, sorte.Koernerreifezahl)"></span>
          // nachher: <span v-if="sorte.Koernermais == 1" v-html="calculatePropertyScore('Gesamttrockenmasse', sorte.Gesamttrockenmasse, sorte.Koernerreifezahl)"></span>


        // this.cornData = response.data
      })
      .catch(e => {
        this.errors.push(e)
      })
		}
}
</script>